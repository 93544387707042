<template>
  <v-container>
    <AddFile tableTitle="Add new File from NL Subjects"></AddFile>
  </v-container>
</template>
<script>


import AddFile from "@/components/nl_file/add_file.vue";

export default {
  name: "NlSubjectPage",
  components: {AddFile}
}
</script>

<style scoped>

</style>