var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"max-width":"600px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var dialog = ref.on;
var attrs = ref.attrs;
return [(_vm.big_btn)?_c('v-btn',_vm._g(_vm._b({},'v-btn',attrs,false),Object.assign({}, dialog)),[_c('v-icon',[_vm._v(" "+_vm._s(_vm.activator_icon)+" ")]),_vm._v(" "+_vm._s(_vm.activator_name)+" ")],1):_vm._e(),(!_vm.big_btn)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var tooltip = ref.on;
return [(!_vm.big_btn)?_c('v-icon',_vm._g(_vm._b({staticClass:"mx-4",attrs:{"small":""}},'v-icon',attrs,false),Object.assign({}, dialog,tooltip)),[_vm._v(" "+_vm._s(_vm.activator_icon)+" ")]):_vm._e()]}}],null,true)},[(!_vm.big_btn)?_c('span',[_vm._v("Edit")]):_vm._e()]):_vm._e()]}}]),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('ValidationObserver',{ref:"observer",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('v-form',{ref:"vform",on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.handle_submit)}}},[_c('v-card',{staticClass:"light-blue lighten-3"},[_c('v-card-title',[_c('span',{staticClass:"text-h5"},[_vm._v(_vm._s(_vm.form_title))])]),_c('v-card-text',[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('ValidationProvider',{attrs:{"mode":"passive","vid":"non_field","name":"None Field","rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return _vm._l((errors),function(err){return _c('ul',{key:err},[_c('li',[_c('span',{staticClass:"red--text"},[_vm._v(_vm._s(err))])])])})}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('ValidationProvider',{attrs:{"mode":"passive","vid":"involved_amount","name":"Involved Amount","rules":"required|min_value:0"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"Involved Amount*","hint":"Total Amount Stuck/Involved in this file","required":"","autocomplete":"nope","type":"number","counter":""},model:{value:(_vm.involved_amount),callback:function ($$v) {_vm.involved_amount=$$v},expression:"involved_amount"}}),_vm._l((errors),function(err){return _c('span',{key:err,staticClass:"red--text"},[_vm._v(_vm._s(err))])})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('validation-provider',{attrs:{"mode":"passive","vid":"dealing_officer","name":"Dealing Officer","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('dealingOfficerInput',{attrs:{"errors":errors,"name":"dealing_officer"},on:{"fetchError":function (e, value){_vm.$emit('fetchError', value)}},model:{value:(_vm.dealing_officer),callback:function ($$v) {_vm.dealing_officer=$$v},expression:"dealing_officer"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('ValidationProvider',{attrs:{"mode":"passive","vid":"branch","name":"Branch","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('BranchInput',{attrs:{"passed_branch":_vm.item.branch,"errors":errors,"name":"branch"},model:{value:(_vm.branch),callback:function ($$v) {_vm.branch=$$v},expression:"branch"}})]}}],null,true)})],1)],1),_c('small',[_vm._v("*indicates required field")])],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":function($event){_vm.dialog = false}}},[_vm._v(" Close ")]),_c('v-btn',{attrs:{"color":"blue darken-1","text":"","type":"submit","loading":_vm.submit_loading}},[_vm._v(" Save ")])],1)],1)],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }