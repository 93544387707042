<template>
  <v-dialog
      max-width="600px"
      v-model="dialog"
  >

    <template v-slot:activator="{ on:dialog, attrs }">
      <v-btn v-if="big_btn" v-bind="attrs" v-on="{...dialog}">
        <v-icon>
          {{ activator_icon }}
        </v-icon>
        {{ activator_name }}
      </v-btn>
      <v-tooltip bottom v-if="!big_btn">
        <template v-slot:activator="{on:tooltip}">
          <v-icon v-if="!big_btn" v-bind="attrs" small
                  v-on="{...dialog,...tooltip}" class="mx-4">
            {{ activator_icon }}
          </v-icon>
        </template>
        <span v-if="!big_btn">Edit</span>
      </v-tooltip>
    </template>

    <ValidationObserver ref="observer" v-slot="{ handleSubmit}">

      <v-form ref="vform" @submit.prevent="handleSubmit(handle_submit)">
        <v-card class="light-blue lighten-3">
          <v-card-title>
            <span class="text-h5">{{ form_title }}</span>
          </v-card-title>
          <v-card-text>
            <v-row>
              <v-col
                  cols="12"
              >
                <ValidationProvider mode="passive" vid="non_field" name="None Field"
                                    rules=""
                                    v-slot="{ errors }">
                  <ul v-for="err in errors" :key="err">
                    <li>
                      <span class="red--text">{{ err }}</span>
                    </li>
                  </ul>
                </ValidationProvider>
              </v-col>

              <v-col
                  cols="12"
              >
                <ValidationProvider mode="passive" vid="involved_amount" name="Involved Amount"
                                    rules="required|min_value:0"
                                    v-slot="{ errors }">
                  <v-text-field
                      label="Involved Amount*"
                      hint="Total Amount Stuck/Involved in this file"
                      required
                      autocomplete="nope"
                      v-model="involved_amount"
                      type="number"
                      counter
                  ></v-text-field>
                  <span v-for="err in errors" :key="err" class="red--text">{{ err }}</span>
                </ValidationProvider>
              </v-col>
              <v-col cols="12">
                <validation-provider mode="passive" vid="dealing_officer" name="Dealing Officer" rules="required"
                                     v-slot="{errors}">
                  <dealingOfficerInput v-model="dealing_officer" :errors="errors"
                                       name="dealing_officer"
                                       @fetchError="(e, value)=>{$emit('fetchError', value)}"></dealingOfficerInput>
                </validation-provider>
              </v-col>
              <v-col
                  cols="12"
              >
                <ValidationProvider mode="passive" vid="branch" name="Branch"
                                    rules="required" v-slot="{ errors }">
                  <BranchInput :passed_branch="item.branch" :errors="errors" name="branch"
                               v-model="branch"
                  ></BranchInput>
                </ValidationProvider>
              </v-col>
            </v-row>
            <small>*indicates required field</small>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
                color="blue darken-1"
                text
                @click="dialog = false"
            >
              Close
            </v-btn>
            <v-btn
                color="blue darken-1"
                text
                type="submit"
                :loading="submit_loading"
            >
              Save
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
    </ValidationObserver>
  </v-dialog>
</template>

<script>
import createAxiosClient from "@/helpers/axios_helper.js"
import {
  ValidationObserver,
  ValidationProvider
} from 'vee-validate'
import "@/validations/office_validations"
import nl_file_url from "@/constants/urls/nl_file";
import BranchInput from "@/components/baseInputs/branchInput.vue";
import dealingOfficerInput from "@/components/baseInputs/dealingOfficerInput.vue";

export default {
  name: "AddEdit",
  props: ['passed_item', 'icon', 'activator_name', 'activator_icon', 'form_title', 'action', 'big_btn'],
  components: {
    BranchInput,
    ValidationProvider,
    ValidationObserver,
    dealingOfficerInput
  },
  computed: {},

  data: () => ({
    submit_loading: false,
    dialog: false,
    dealing_officer: null,
    involved_amount: 0,
    search: "",
    branch: null,
    branchCopy: [],
    branch_loading: false,
  }),
  methods: {
    async add_new_item() {

      let client = createAxiosClient()
      let data = {
        dealing_officer_id: this.dealing_officer,
        involved_amount: this.involved_amount,
        nls_subject_id: this.passed_item.id,
        branch_id: this.branch,
        is_nls_file: true
      }
      // console.log(this.zone.circle)
      await client.post(nl_file_url.file.add, data)
          .then((resp) => {
            try {
              this.$store.commit('setJWT', resp.data.key)
              this.$refs.vform.reset()
              this.$refs.vform.resetValidation()
              this.dialog = false
              this.$emit('form_status', {status: true, fileObj: resp.data.data})
              console.log("emitted form status")
              // this.$emit('snackbar_st', 'New Item Added')
            } catch {
              this.submit_loading = false
            }

          })
          .catch((error) => {
            if (error.response.data) {
              this.$store.commit('setJWT', error.response.data.key)
              this.$refs.observer.setErrors({
                ...error.response.data.errors,
                branch: error.response.data.errors.office
              });

            }
            this.submit_loading = false
          })
      this.submit_loading = false
    },
    // async edit_item() {
    //   let client = createAxiosClient()
    //   console.log(this.item)
    //   let data = {
    //     name: this.item.name,
    //     address: this.item.address,
    //     phone: this.item.phone,
    //     email: this.item.email,
    //     doc_details: this.item.doc_details,
    //     office: this.item.branch.id,
    //     branch: this.item.branch.id,
    //   }
    //   await client.patch(nls_urls.subject.edit.replace('{id}', this.item.id), data)
    //       .then((resp) => {
    //         this.$store.commit('setJWT', resp.data.key)
    //         this.dialog = false
    //         this.$emit('form_status', true)
    //         this.$emit('snackbar_st', `User#${this.user.username} Updated`)
    //       })
    //       .catch((error) => {
    //         this.submit_loading = false
    //         if (error.response.data) {
    //           this.$store.commit('setJWT', error.response.data.key)
    //           this.$refs.observer.setErrors({
    //             ...error.response.data.errors
    //           });
    //
    //         }
    //       })
    //   this.submit_loading = false
    // },
    handle_submit() {
      this.submit_loading = true
      if (this.action === 'add') {
        this.add_new_item()
      } else if (this.action === 'edit') {
        this.edit_item()
      }
    },
  },
  watch: {},
  mounted() {
    if (this.passed_item) {
      this.item = JSON.parse(JSON.stringify(this.passed_item))
      this.office = this.item.branch
      console.log(this.passed_item)
    } else {
      this.item.branch
    }
  }
}
</script>

<style scoped>
</style>