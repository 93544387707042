<script>
import createAxiosClient from "@/helpers/axios_helper";

export default {
  // $_veeValidate: {
  //   // must not be arrow functions.
  //   // the name getter
  //   name() {
  //     return this.name;
  //   },
  //   // the value getter
  //   value() {
  //     return this.value; // `this` is the component instance, `value` is the prop we added.
  //   }
  // },
  name: "branchInput",
  props: ["passed_branch", "errors", "name"],
  model: {
    prop: 'value',
    event: 'input'
  },
  // components: {ValidationProvider},
  data: () => ({
    search: '',
    branches: [],
    branch: [],
    branch_loading: false,
  }),
  methods: {
    async get_branches() {
      let client = createAxiosClient()
      this.branch_loading = true
      this.axios_conf
      await client.get("user/branch_office")
          .then((resp) => {
            this.branches = resp.data.branches
            this.branchCopy = [...this.branch]
            this.$store.commit('setJWT', resp.data.key)
          })
      this.branch_loading = false
    },
    searchBranch() {
      if (!this.search) {
        this.branches = this.branchCopy;
      }
      this.branches = this.branchCopy.filter((br) => {
        return br.code.toString() === this.search || br.name.toLowerCase().indexOf(this.search.toLowerCase()) > -1;
      });
    },
    run_mounted_functions() {
      const get_br = async () => {
        await this.get_branches()
      }
      get_br()
    },
    handleInput(e) {
      this.$emit('input', e)
    }
  },
  mounted() {
    this.run_mounted_functions()

    if (this.passed_branch) {
      if (this.passed_branch.branch) {
        this.branch = this.passed_branch
      }
    }
  }
}
</script>

<template>
  <span>
    <v-select label="Select a branch*" v-model="branch" hint="Branch Name"
              :items="branches" item-text="name" clearable
              item-value="id" item-color="primary"
              required
              :name="name"
              @input="handleInput"
    >
      <template v-slot:prepend-item>
        <v-list-item>
          <v-list-item-content>
            <v-text-field v-model="search" placeholder="Search"
                          @input="searchBranch"></v-text-field>
          </v-list-item-content>
        </v-list-item>
        <v-divider class="mt-2"></v-divider>
      </template>
    </v-select>
    <span v-for="err in errors" :key="err" class="red--text">{{ err }}</span>
  </span>
</template>

<style scoped>

</style>