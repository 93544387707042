<template>
  <v-container v-if="has_view_permission">
    <v-card class="my-1 pa-3 blue accent-1">
      <v-card-title>

        <v-btn :loading="reload_btn" @click="get_list_data" class="ma-1">
          <v-icon>
            mdi-reload
          </v-icon>
          Reload Data Table
        </v-btn>
      </v-card-title>
    </v-card>
    <v-card class="yellow lighten-2">
      <v-data-table
          :headers="column_headers"
          :items="table_items"
          sort-by="id"
          class="elevation-5 yellow lighten-2"
          :loading="table_loading"
          :search="search"
          loading-text="Loading... Please wait"
          item-key="id"
          name="data_table"
      >
        <template v-slot:top>
          <v-card-title
              flat
          >
            {{ tableTitle }}
            <v-spacer></v-spacer>
            <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                label="Search"
                single-line
                hide-details
            ></v-text-field>
          </v-card-title>
        </template>
        <template v-slot:item.actions="{ item }">
          <addFileForm v-if="add_button" activator_name="Add New"
                       form_title="Add New"
                       activator_icon="mdi-plus-thick" action="add"
                       @fetchError="handleInputError"
                       big_btn="true" :passed_item="item"
                       @form_status="details_page"></addFileForm>
        </template>
        <template v-slot:expanded-item="{ headers,item}">
          <td :colspan="headers.length" :key="item.id">
            <NLSOwner tableTitle="Owners" :nlsItem="item"/>
          </td>
        </template>
      </v-data-table>
    </v-card>
  </v-container>
</template>

<script>
import createAxiosClient from "@/helpers/axios_helper.js";
import nls_urls from "@/constants/urls/nls_urls.js"
import nls_perms from "@/constants/permissions/nls_perms.js"
import addFileForm from "@/components/nl_file/sub_components/addFileForm";
import NLSOwner from "@/components/nls_subject/owner_component/NLSOwner.vue";


export default {
  name: "AddFile",
  components: {NLSOwner, addFileForm},
  props: ["tableTitle"],
  computed: {
    has_view_permission() {
      return this.$store.getters.permissionCheckers(nls_perms.subject.list)
    },
    add_button() {
      return this.$store.getters.permissionCheckers(nls_perms.subject.add)
    },
  },
  data: () => ({
    reload_btn: false,
    table_loading: false,
    column_headers: [
      {
        text: 'Tracking Number',
        align: 'center',
        filterable: true,
        value: 'tracking_number',
      },
      {
        text: 'Name',
        align: 'center',
        filterable: true,
        value: 'name',
      },
      {
        text: 'Branch Code',
        align: 'center',
        filterable: true,
        value: 'branch.code',
      },
      {
        text: 'Branch Name',
        align: 'center',
        filterable: true,
        value: 'branch.name',
      },
      {
        text: 'Actions',
        align: 'center',
        filterable: false,
        value: 'actions',
      },
    ],
    table_items: [],
    search: '',
    show_snackbar: false,
    snackbar_text: null,
  }),
  methods: {
    async get_list_data() {
      let client = createAxiosClient()
      this.reload_btn = true
      this.table_loading = true
      this.table_items = []
      await client.get(nls_urls.subject.list).then((resp) => {
        // console.log(resp)
        this.table_items = resp.data.data
        this.$store.commit('setJWT', resp.data.key)
      })
      this.reload_btn = false
      this.table_loading = false
    },
    // snackbars(value) {
    //   // console.log(value)
    //   this.snackbar_text = value
    //   this.show_snackbar = true
    // },
    handleInputError(e, value) {
      this.snackbars(value)
    },
    details_page(obj) {
      console.log('value: ', obj.status)
      console.log('obj: ', obj)
      this.$router.push({name: 'nlFileDetails', params: {fileid: obj.fileObj.id}})
    }
  },
  mounted() {
    this.get_list_data();
  }
}
</script>

<style scoped>
tbody tr:hover {
  background: #82B1FF !important;
}
</style>