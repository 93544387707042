<script>
import personnel_urls from "@/constants/personnel_urls";
import createAxiosClient from "@/helpers/axios_helper.js"

export default {
  name: "dealingOfficerInput",
  props: ['value', 'errors', 'name'],
  data: () => ({
        dealing_officer: [],
        dealing_officer_loading: false,
        dealing_officer_list_url: personnel_urls.personnel.list,
        modelValue: null
      }
  ),
  methods: {
    async get_list() {
      let client = createAxiosClient()
      console.log("running mounted functions")
      this.submit_loading = true
      this.dealing_officer_loading = true
      await client.get(this.dealing_officer_list_url).then((resp) => {
        this.dealing_officer = resp.data.personnels
        this.$store.commit('setJWT', resp.data.key)
        this.submit_loading = false
        this.dealing_officer_loading = false
      }).catch((error) => {
        this.$emit('fetchError', `unable to fetch dealing officer, ${error}`)
      })
    },
    run_mounted_functions() {
      const get_data = async () => {
        await this.get_list()
      }
      get_data()
    },
  },
  mounted() {
    this.run_mounted_functions()
  }
}
</script>

<template>
  <span>
    <v-autocomplete
        label="Dealing Officer*"
        hint="Search by personnel no or name"
        :items="dealing_officer"
        :item-text="(item)=>item.personnel_no + ' | ' + item.name"
        item-value="id"
        v-model="modelValue"
        required
        autocomplete="nope"
        :loading="dealing_officer_loading"
        @input="(e)=>{$emit('input', e)}"
        :name="name"
    >
    </v-autocomplete>
  <span v-for="err in errors" :key="err" class="red--text">{{ err }}</span>
  </span>
</template>

<style scoped>

</style>