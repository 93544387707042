<template>
  <v-dialog
      max-width="600px"
      v-model="dialog"
  >

    <template v-slot:activator="{ on:dialog, attrs }">
      <v-btn v-if="big_btn" v-bind="attrs" v-on="{...dialog}">
        <v-icon>
          {{ activator_icon }}
        </v-icon>
        {{ activator_name }}
      </v-btn>
      <v-tooltip bottom v-if="!big_btn">
        <template v-slot:activator="{on:tooltip}">
          <v-icon v-if="!big_btn" v-bind="attrs" small
                  v-on="{...dialog,...tooltip}" class="mx-4">
            {{ activator_icon }}
          </v-icon>
        </template>
        <span v-if="!big_btn">Edit</span>
      </v-tooltip>
    </template>

    <ValidationObserver ref="observer" v-slot="{ handleSubmit}">

      <v-form ref="vform" @submit.prevent="handleSubmit(handle_submit)">
        <v-card class="light-blue lighten-3">
          <v-card-title>
            <span class="text-h5">{{ form_title }}</span>
          </v-card-title>
          <v-card-text>
            <v-row>
              <v-col
                  cols="12"
              >
                <ValidationProvider mode="passive" vid="non_field" name="None Field"
                                    rules=""
                                    v-slot="{ errors }">
                  <ul v-for="err in errors" :key="err">
                    <li>
                      <span class="red--text">{{ err }}</span>
                    </li>
                  </ul>
                </ValidationProvider>
              </v-col>

              <v-col
                  cols="12"
              >
                <ValidationProvider mode="passive" vid="name" name="Name" rules="required" v-slot="{ errors }">
                  <v-text-field
                      label="Name*"
                      hint="Subject Name"
                      required
                      autocomplete="nope"
                      v-model="item.name"
                      maxlength="50"
                      counter
                  ></v-text-field>
                  <span v-for="err in errors" :key="err" class="red--text">{{ err }}</span>
                </ValidationProvider>
              </v-col>

              <v-col
                  cols="12"
              >
                <ValidationProvider mode="eager" vid="address" name="Address"
                                    v-slot="{ errors }">
                  <v-textarea
                      v-model="item.address"
                      label="Address"
                      hint="Subject Address"
                      dense
                      clearable
                      auto-grow
                      rows="1"
                      maxlenth="1024"
                      counter="1024"
                  ></v-textarea>
                  <ul>
                    <li v-for="err in errors" :key="err">
                      <span class="red--text">{{ err }}</span>
                    </li>
                  </ul>
                </ValidationProvider>
              </v-col>
              <v-col
                  cols="12"
              >
                <ValidationProvider mode="passive" vid="nid" name="NID" rules="" v-slot="{ errors }">
                  <v-text-field
                      label="NID"
                      hint="Subject NID"
                      autocomplete="nope"
                      v-model="item.nid"
                      maxlength="20"
                      counter
                  ></v-text-field>
                  <span v-for="err in errors" :key="err" class="red--text">{{ err }}</span>
                </ValidationProvider>
              </v-col>
              <v-col
                  cols="12"
              >
                <ValidationProvider mode="passive" vid="passport" name="Passport" rules="" v-slot="{ errors }">
                  <v-text-field
                      label="Passport"
                      hint="Subject Passport"
                      autocomplete="nope"
                      v-model="item.passport"
                      maxlength="20"
                      counter
                  ></v-text-field>
                  <span v-for="err in errors" :key="err" class="red--text">{{ err }}</span>
                </ValidationProvider>
              </v-col>
              <v-col
                  cols="4"
              >
                <ValidationProvider mode="passive" vid="email" name="Email" rules="" v-slot="{ errors }">
                  <v-text-field
                      label="Email"
                      hint="Subject's Email"
                      required
                      autocomplete="nope"
                      v-model="item.email"
                      maxlength="50"
                      counter
                  ></v-text-field>
                  <span v-for="err in errors" :key="err" class="red--text">{{ err }}</span>
                </ValidationProvider>
              </v-col>
              <v-col
                  cols="4"
              >
                <ValidationProvider mode="passive" vid="phone" name="Phone" rules="" v-slot="{ errors }">
                  <v-text-field
                      label="Phone"
                      hint="Subject's Email"
                      required
                      autocomplete="nope"
                      v-model="item.phone"
                      maxlength="20"
                      counter
                  ></v-text-field>
                  <span v-for="err in errors" :key="err" class="red--text">{{ err }}</span>
                </ValidationProvider>
              </v-col>

            </v-row>
            <small>*indicates required field</small>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
                color="blue darken-1"
                text
                @click="dialog = false"
            >
              Close
            </v-btn>
            <v-btn
                color="blue darken-1"
                text
                type="submit"
                :loading="submit_loading"
            >
              Save
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
    </ValidationObserver>
  </v-dialog>
</template>

<script>
import axios from "axios";
import {ValidationObserver, ValidationProvider} from 'vee-validate'
import "@/validations/office_validations"
import nls_urls from "@/constants/urls/nls_urls";

export default {
  name: "AddEdit",
  props: ['passed_item', 'icon', 'activator_name', 'activator_icon', 'form_title', 'action', 'big_btn', 'parent_item'],
  components: {ValidationProvider, ValidationObserver},
  computed: {
    axios_conf() {
      axios.defaults.baseURL = this.$store.state.baseURL
      axios.defaults.headers.common['Authorization'] = `mamla ${this.$store.state.accessToken}`
      axios.defaults.headers.common['multi'] = 'true'
      return 0
    },
  },

  data: () => ({
    submit_loading: false,
    dialog: false,
    nls_item: null,
    item: {
      id: null,
      name: null,
      address: null,
      phone: null,
      email: null,
      nid: null,
      passport: null,
    },

    search: "",
    branch: [{name: 'test', code: 'test', id: 'test'}],
    branchCopy: [],
    branch_loading: false,
  }),
  methods: {
    async add_new_item() {
      this.axios_conf
      let data = {
        ...this.item,
        subject_id: this.parent_item.id,
        office: this.parent_item.branch.id
      }
      await axios.post(nls_urls.owner.add, data)
          .then((resp) => {
            try {
              this.$store.commit('setJWT', resp.data.key)
              this.$refs.vform.reset()
              this.$refs.vform.resetValidation()
              this.dialog = false
              this.$emit('form_status', true)
              this.$emit('snackbar_st', 'New Item Added')
            } catch {
              this.submit_loading = false
            }

          })
          .catch((error) => {
            if (error.response.data) {
              this.$store.commit('setJWT', error.response.data.key)
              this.$refs.observer.setErrors({
                ...error.response.data.errors,
              });

            }
            this.submit_loading = false
          })
      this.submit_loading = false
    },
    async edit_item() {
      this.axios_conf
      console.log(this.item)
      let data = {
        ...this.item
      }
      await axios.patch(nls_urls.owner.edit.replace('{id}', this.item.id), data)
          .then((resp) => {
            this.$store.commit('setJWT', resp.data.key)
            this.dialog = false
            this.$emit('form_status', true)
            this.$emit('snackbar_st', `User#${this.user.username} Updated`)
          })
          .catch((error) => {
            this.submit_loading = false
            if (error.response.data) {
              this.$store.commit('setJWT', error.response.data.key)
              this.$refs.observer.setErrors({
                ...error.response.data.errors
              });

            }
          })
      this.submit_loading = false
    },
    handle_submit() {
      this.submit_loading = true
      if (this.action === 'add') {
        this.add_new_item()
      } else if (this.action === 'edit') {
        this.edit_item()
      }
    },
  },
  watch: {},
  mounted() {
    if (this.passed_item) {
      this.item = JSON.parse(JSON.stringify(this.passed_item))
    }
  }
}
</script>

<style scoped>
</style>